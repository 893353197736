export const environment = {
  id: "staging",
  production: false,
  API_V3: "https://staging.gw.pouchnation.com",
  FIREBASE: {
    apiKey: "AIzaSyBwTQaxZOqLXOCDe0Aytj8vw9qPB1UkJVo",
    authDomain: "ms-communication-ae098.firebaseapp.com",
    databaseURL: "https://ms-communication-ae098.firebaseio.com",
    projectId: "ms-communication-ae098",
    storageBucket: "ms-communication-ae098.appspot.com",
    messagingSenderId: "139918626078",
    appId: "1:139918626078:web:c8900ad0dffdb794538d0f",
    measurementId: "G-BG2Z7LL2BK",
  },
  MIXPANEL_TOKEN: "dd2b9cdaccee496cd63dd45ac4f09f57",
  FEATURE_FLAG: {
    clientSideID: "5f7ad2062229060b440fd8bf",
  },
};
